@import "mixins"
.arrow
  position: absolute
  width: 0
  height: 0
  border-width: 16px 8px 0 8px
  border-color: #ccd4e1 transparent transparent transparent
  border-style: solid
  bottom: -15px
  &.left
    left: 48px
  &.right
    right: 48px